export default {
  selectors: {
    search: '.search',
    header: '.batcom-header',
    searchField: '.cmp-search__field',
    searchInput: '.cmp-search__input',
    searchResultsContainer: '.cmp-search__results-container',
    searchResultsWrapper: '.cmp-search__results-wrapper',
    searchResults: '.cmp-search__results',
    noResults: '.cmp-search__results-none',
    resultsFound: '.cmp-search__results-found',
    resultsNumber: '.cmp-search__results-found--number',
    clearButton: '.cmp-search__clear',
  },
  classes: {
    noScroll: 'noScroll',
    visible: 'visible',
    open: 'open',
    hidden: 'hidden',
  },
};
