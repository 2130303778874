import { register } from '@netcentric/component-loader';
import config from './batcom-search.config';
import * as Events from '../../../commons/constants/events';

class Search {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.searchMinLength = parseInt(this.el.firstElementChild.getAttribute('data-cmp-min-length'), 10);
    this.init();
  }

  init() {
    this.setRefs();
    this.setEventListeners();
    this.setObserver();
    this.setPubSubListeners();
  }

  setRefs() {
    this.searchMenu = this.el;
    this.searchToggle = this.el.previousElementSibling;
    this.searchField = this.el.querySelector(this.selectors.searchField);
    this.searchInput = this.el.querySelector(this.selectors.searchInput);
    this.searchResultsContainer = this.el.querySelector(this.selectors.searchResultsContainer);
    this.searchResultsWrapper = this.el.querySelector(this.selectors.searchResultsWrapper);
    this.searchResults = this.el.querySelector(this.selectors.searchResults);
    this.noResults = this.el.querySelector(this.selectors.noResults);
    this.resultsFound = this.el.querySelector(this.selectors.resultsFound);
    this.resultsNumber = this.el.querySelector(this.selectors.resultsNumber);
    this.clearButton = this.el.querySelector(this.selectors.clearButton);
  }

  setEventListeners() {
    // Open Search
    if (this.searchToggle) {
      this.searchToggle.addEventListener('click', () => {
        if (this.searchToggle.closest('.search')) {
          this.toggleSearch();
        }
        if (this.searchToggle.closest('.batcom-header')) {
          window.PubSub.publish(Events.OPEN_SEARCH);
        }
      });
    }

    // Close Search
    this.clearButton.addEventListener('click', () => {
      if (this.searchToggle.closest('.search')) {
        this.toggleSearch();
      }

      if (this.searchToggle.closest('.batcom-header')) {
        window.PubSub.publish(Events.CLOSE_SEARCH);
      }
    });

    this.searchInput.addEventListener('input', () => {
      this.updateResultsText();
    });

    this.searchInput.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.resetSearch();
      }
    });
  }

  // Events from SearchHeader.js
  setPubSubListeners() {
    const that = this;
    window.PubSub.subscribe(Events.RESET_SEARCH, () => {
      if (that.el.closest('.batcom-header')) {
        this.resetSearch();
      }
    });

    window.PubSub.subscribe(Events.FOCUS_SEARCH_INPUT, () => {
      if (that.el.closest('.batcom-header')) {
        this.focusSearchInput();
      }
    });
  }

  setObserver() {
    const observer = new MutationObserver(this.setResultsNumber.bind(this));
    observer.observe(this.searchResults, { childList: true, subtree: true });
  }

  toggleSearch() {
    if (!this.searchMenu.closest(this.selectors.header)) {
      if (this.searchMenu.classList.contains(this.classes.open)) {
        this.searchToggle.classList.remove(this.classes.hidden);
        this.searchMenu.classList.remove(this.classes.open);
        this.clearButton.classList.remove(this.classes.open);
        this.clearButton.setAttribute('aria-expanded', 'false');
        this.resetSearch();
      } else {
        this.clearButton.classList.add(this.classes.open);
        this.clearButton.setAttribute('aria-expanded', 'true');
        this.searchToggle.classList.add(this.classes.hidden);
        this.searchMenu.classList.add(this.classes.open);
        this.focusSearchInput();
      }
    }
  }

  resetSearch() {
    this.searchInput.value = '';
    this.searchResultsContainer.classList.remove(this.classes.visible);
    this.noResults.classList.remove(this.classes.visible);
    this.resultsFound.classList.remove(this.classes.visible);
    this.focusSearchInput();
  }

  focusSearchInput() {
    this.searchInput.focus();
  }

  updateResultsText() {
    const searchValue = this.searchInput.value;

    setTimeout(() => {
      const resultsNumber = this.searchResults.childElementCount;

      if (searchValue.length >= this.searchMinLength && resultsNumber === 0) {
        this.searchResultsWrapper.classList.remove(this.classes.visible);
        this.resultsFound.classList.remove(this.classes.visible);
        this.noResults.classList.add(this.classes.visible);
        this.searchResultsContainer.classList.add(this.classes.visible);
      } else if (searchValue.length >= this.searchMinLength && resultsNumber > 0) {
        this.noResults.classList.remove(this.classes.visible);
        this.resultsFound.classList.add(this.classes.visible);
        this.searchResultsWrapper.classList.add(this.classes.visible);
        this.searchResultsContainer.classList.add(this.classes.visible);
      } else if (searchValue.length === 0) {
        this.searchResultsWrapper.classList.remove(this.classes.visible);
        this.searchResultsContainer.classList.remove(this.classes.visible);
        this.noResults.classList.remove(this.classes.visible);
        this.resultsFound.classList.remove(this.classes.visible);
      }
    }, 500);
  }

  setResultsNumber(mutationsList) {
    mutationsList.map(mutation => {
      if (mutation.type === 'childList') {
        const resultsNumber = this.searchResults.childElementCount;

        if (resultsNumber > 0) {
          this.resultsNumber.innerText = resultsNumber;
        }
      }
      return this.resultsNumber.innerText;
    });
  }
}

register({ Search });
